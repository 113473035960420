export default {
  PDV_LETTER_SENT: {
    key: 'PDV_LETTER_SENT',
    description: 'PDV.BANNER.LETTER',
    severity: 'primary',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'personal',
      },
    },
  },
  PDV_FAILED: {
    key: 'PDV_FAILED',
    description: 'PDV.BANNER.FAILED',
    severity: 'primary',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'personal',
      },
    },
  },
  PDV_FULLY_FAILED: {
    key: 'PDV_FULLY_FAILED',
    description: 'PDV.BANNER.FULLY_FAILED',
    severity: 'red',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'personal',
      },
    },
  },
  PAYMENT_DATA_MISSING: {
    key: 'PAYMENT_DATA_MISSING',
    description: 'DASHBOARD.MISSING_PAYMENT_DATA',
    severity: 'red',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'financial',
      },
    },
  },
  TAX_FORM_EXPIRES: {
    key: 'TAX_FORM_EXPIRES',
    description: 'DASHBOARD.TAXATION_FORM_ABOUT_TO_EXPIRE',
    severity: 'primary',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'taxation',
      },
    },
  },
  TAX_FORM_INVALID: {
    key: 'TAX_FORM_INVALID',
    description: 'DASHBOARD.TAXATION_FORM_INVALID',
    severity: 'primary',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'taxation',
      },
    },
  },
  TAXATION_INVALID: {
    key: 'TAXATION_INVALID',
    description: 'ACCOUNT.TAXATION_WARNING_LINK',
    severity: 'red',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'taxation',
      },
    },
  },
  TAXATION_INCONSISTENT: {
    key: 'TAXATION_INCONSISTENT',
    description: 'ACCOUNT.TAXATION.INCONSISTENCY_WARNING',
    severity: 'red',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'taxation',
      },
    },
  },
  MFA_MISSING: {
    key: 'MFA_MISSING',
    description: 'MFA.BANNER',
    linkText: 'MFA.STATUS.START_SETUP',
    severity: 'primary',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'security',
      },
    },
  },
  TROLLEY_PAYMENT_DATA_MISSING: {
    key: 'TROLLEY_PAYMENT_DATA_MISSING',
    description: 'DASHBOARD.TROLLEY_MISSING_PAYMENT_DATA',
    severity: 'red',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'finance',
      },
    },
  },
  TROLLEY_TAX_FORM_MISSING: {
    key: 'TROLLEY_TAX_FORM_MISSING',
    description: 'DASHBOARD.TROLLEY_TAX_FORM_MISSING',
    severity: 'red',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'finance',
      },
    },
  },
  TROLLEY_TAX_FORM_INVALID: {
    key: 'TROLLEY_TAX_FORM_INVALID',
    description: 'DASHBOARD.TROLLEY_TAX_FORM_INVALID',
    severity: 'red',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'finance',
      },
    },
  },
  TROLLEY_ADDRESS_MISMATCH: {
    key: 'TROLLEY_ADDRESS_MISMATCH',
    description: 'DASHBOARD.TROLLEY_ADDRESS_MISMATCH',
    severity: 'red',
    routeOnClick: {
      route: 'partnerarea.account',
      params: {
        subpage: 'finance',
      },
    },
  },
};

<template>
  <div class="mfa-required">
    <div
      v-if="currentMfaState.key === MFA_STATES.SETUP.key && !skipMfa"
      class="mfa-warning"
      :class="{ banner: showContentWithoutPass }"
    >
      <img src="/images/2fa.png" alt="2FA" />
      <div class="mfa-warning-content">
        <Icon icon="attention" />
        <div>
          <h3>{{ $t('MFA_REQUIRED.HEADING') }}</h3>
          <p>
            {{ $t('MFA_REQUIRED.TEXT') }}
            <SmartFaqLink
              v-if="faqLink"
              :link="faqLink"
              title="MFA_REQUIRED.LEARN_MORE"
              hideIcon
            />
          </p>
          <div class="actions">
            <button class="btn btn-ghost" @click="goToMfaSetup">
              {{ $t('MFA_REQUIRED.CTA') }}
            </button>
            <button class="link-main" @click="skip">
              {{ $t('MFA_REQUIRED.SKIP') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        skipMfa ||
        props.showContentWithoutPass ||
        currentMfaState.key === MFA_STATES.ACTIVATED.key
      "
      class="content-wrapper"
    >
      <slot
        :mfaRequirementPassed="
          skipMfa || currentMfaState.key === MFA_STATES.ACTIVATED.key
        "
      />
      <div
        v-if="
          !skipMfa &&
          overlayContentWithoutPass &&
          currentMfaState.key !== MFA_STATES.ACTIVATED.key
        "
        class="mfa-required-content-overlay"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import MFA_STATES from '@/states/mfaStates';
import analytics from '@/tracking/analytics';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import {
  setLocalStorageItemWithExpiry,
  getLocalStorageItemWithExpiry,
} from '@/utils';

const props = defineProps({
  showContentWithoutPass: Boolean,
  overlayContentWithoutPass: Boolean,
});

const store = useStore();
const currentMfaState = computed(() => store.state.user.mfaStatus);
const skipMfa = ref(!!getLocalStorageItemWithExpiry('skipMfa'));
const router = useRouter();
const faqLink = smartFaqLinks.MFA;

const goToMfaSetup = () => {
  router.push({
    name: 'partnerarea.account',
    params: { subpage: 'security' },
  });
  analytics.logEvent('mfa-required-setup');
};

const skip = () => {
  skipMfa.value = true;
  setLocalStorageItemWithExpiry('skipMfa', true, 7 * 24 * 60 * 60 * 1000); // remember mfa skip for one week
  analytics.logEvent('mfa-required-skip');
};

onMounted(() => store.dispatch('user/updateMfaStatus'));
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.mfa-warning {
  .mfa-warning-content {
    display: flex;
    align-items: center;
  }

  .icon {
    display: none;
    margin: 0 8px auto 0;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  &.banner {
    padding: 16px;
    margin: 0 0 24px 0;
    border-radius: 8px;
    background-color: $pa-color-yellow-light;

    img {
      display: none;
    }

    .icon {
      display: initial;
    }

    button,
    a {
      color: #3b3007;
      border-color: #3b3007;
    }
  }

  button {
    font-weight: normal;
    border-width: 1px;

    &.btn-ghost {
      padding: 12px 24px;
    }
  }

  a {
    font-weight: normal;
    text-decoration: underline;
    display: inline-block;
  }

  img {
    width: 256px;
  }

  h3 {
    margin: 0;
    line-height: 24px;
  }

  p {
    margin: 16px 0;
  }

  .actions {
    display: flex;
    gap: 16px;
  }
}

.content-wrapper {
  position: relative;
}

.mfa-required-content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  opacity: 0.8;
}
</style>

<template>
  <DetailPage fullWidth heading="DESIGNS.MULTI_CREATION.PANEL.HEADING">
    <template v-slot:left-nav>
      <button class="link link-main icon-link" @click="back">
        <Icon icon="left" />
        {{ $t('GENERAL.BACK') }}
      </button>
    </template>
    <template v-slot:right-nav>
      <VDropdown :disabled="selectedMotives.length >= 2">
        <ProgressButton
          class="btn btn-primary icon-btn"
          :onClick="save"
          label="GENERAL.APPLY"
          :disabled="selectedMotives.length < 2"
        />
        <template #popper>
          {{ $t('DESIGNS.MULTI_CREATION.MINIMUM') }}
        </template>
      </VDropdown>
    </template>
    <template v-slot:content>
      <div class="multi-design-idea">
        <div class="selected-motives overflow-y">
          <h1>{{ $t('DESIGNS.MULTI_CREATION.PANEL.HEADING') }}</h1>
          <p
            class="subheading"
            v-html="$t('DESIGNS.MULTI_CREATION.PANEL.SUBHEADING')"
          ></p>
          <p class="general-error" v-if="displayGeneralError">
            {{ $t('DESIGNS.MULTI_CREATION.GENERAL_ERROR') }}
          </p>
          <PrintAreaSelector
            :printAreas="printAreas"
            :removable="true"
            @change="onPrintAreaChange"
            @remove="toggleMotiveSelection"
            @mainDesignSelected="mainDesignSelected"
            mainDesignSelection
          />
        </div>
        <div class="multi-design-content">
          <div class="overflow-y">
            <div>
              <div class="header">
                <div>
                  <h2>{{ $t('DESIGNS.MULTI_CREATION.HEADING') }}</h2>
                  <p>{{ $t('DESIGNS.MULTI_CREATION.SUBHEADING') }}</p>
                </div>
                <div class="search-wrapper">
                  <div class="search-field">
                    <input
                      type="text"
                      v-model="searchTerm"
                      :class="{ filled: searchTerm }"
                      @keyup.enter="search"
                      @blur="search"
                      :placeholder="$t('DESIGNS.LIST.PLACEHOLDER.SEARCH')"
                    />
                    <a
                      href="#"
                      class="reset-link"
                      @click.prevent="clearSearchTerm"
                    >
                      <Icon icon="close" />
                    </a>
                    <a href="#" class="search-link">
                      <Icon icon="lens" />
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="motives.length" class="motives">
                <div
                  class="motive"
                  v-for="motive in motives"
                  :key="motive.designId"
                  :class="{
                    disabled:
                      !motiveData[motive.designId].currentPrintArea &&
                      !motiveData[motive.designId].nextFreeLocation,
                    selected: motiveData[motive.designId].currentPrintArea,
                  }"
                >
                  <div class="card" @click="toggleMotiveSelection(motive)">
                    <div
                      class="image-wrapper design-checked-background"
                      :class="{ dark: motive.backgroundColor }"
                    >
                      <div class="image">
                        <SprdImage
                          :src="`${motive.resources[0].href},height=200,width=200`"
                          alt="Design Image"
                        />
                      </div>
                      <Checkbox
                        class="checkbox"
                        :model-value="!!getPrintAreaForMotive(motive)"
                        @update:model-value="toggleMotiveSelection(motive)"
                      />
                    </div>
                    <div class="footer">
                      {{ motive.name }}
                    </div>
                    <VDropdown class="disabled-overlay">
                      <div></div>
                      <template #popper>
                        <div class="no-printarea-available">
                          <p class="topic">
                            {{
                              $t('DESIGNS.MULTI_CREATION.NOT_SUITABLE.HEADING')
                            }}
                          </p>
                          <p>
                            {{
                              $t(
                                'DESIGNS.MULTI_CREATION.NOT_SUITABLE.VALID_LOCATIONS'
                              )
                            }}
                          </p>
                          <p>{{ getTranslatedMotiveLocations(motive) }}</p>
                        </div>
                      </template>
                    </VDropdown>
                  </div>
                </div>
                <div
                  v-for="index in [1, 2, 3, 4, 5, 6, 7]"
                  :key="`placeholder-${index}`"
                  class="motive motive-placeholder"
                >
                  <div class="card"></div>
                </div>
              </div>
              <Pagination
                :total="totalMotivesCount"
                :pageSize="pagination.pageSize"
                :currentPage="pagination.currentPage"
                @pageChange="onPageChange"
              />

              <div
                v-if="initialized && totalMotivesCount === 0"
                class="no-search-result"
              >
                {{ $t('DESIGNS.LIST.NO_SEARCH_RESULT') }}
              </div>
              <div v-if="!initialized" class="loader">
                <img
                  src="/images/loader.svg"
                  alt="Loader"
                  class="sprd-loader"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </DetailPage>
</template>

<script>
import apiClient from '@/api/client/apiClient';
import SprdImage from '@/image/SprdImage.vue';
import Checkbox from '@/checkbox/Checkbox.vue';
import Pagination from 'src/app/commons/pagination/Pagination.vue';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import PrintAreaSelector from './PrintAreaSelector.vue';
import DetailPage from 'src/app/components/detailPage/DetailPage.vue';

export default {
  name: 'CreateMultiDesignIdea',
  components: {
    SprdImage,
    Checkbox,
    Pagination,
    ProgressButton,
    PrintAreaSelector,
    DetailPage,
  },
  data() {
    return {
      searchTerm: '',
      motives: [],
      totalMotivesCount: 0,
      printAreas: [
        {
          id: 'FRONT',
          name: 'DESIGNS.PRINTAREA.LOCATION.FRONT',
          motive: null,
        },
        {
          id: 'BACK',
          name: 'DESIGNS.PRINTAREA.LOCATION.BACK',
          motive: null,
        },
        {
          id: 'LEFT',
          name: 'DESIGNS.PRINTAREA.LOCATION.LEFT',
          motive: null,
        },
        {
          id: 'RIGHT',
          name: 'DESIGNS.PRINTAREA.LOCATION.RIGHT',
          motive: null,
        },
      ],
      motiveData: {},
      pagination: {
        currentPage: 0,
        pageSize: 50,
      },
      initialized: false,
      displayGeneralError: false,
    };
  },
  computed: {
    selectedMotives() {
      return this.printAreas
        .filter((printArea) => printArea.motive)
        .map((printArea) => printArea.motive);
    },
  },
  async created() {
    this.fetchMotives();
  },
  methods: {
    mainDesignSelected(motive) {
      const printArea = this.getPrintAreaForMotive(motive);
      if (printArea && printArea.motive) {
        this.printAreas
          .filter((pa) => !!pa.motive)
          .forEach((pa) => {
            pa.motive.main = false;
          });
        printArea.motive.main = true;
      }
      return false;
    },
    async fetchMotives() {
      const { data } = await apiClient.request({
        method: 'GET',
        url: `/api/v1/users/${this.$store.state.user.data.id}/motives`,
        params: {
          limit: this.pagination.pageSize,
          offset: this.pagination.currentPage * this.pagination.pageSize,
          q: this.searchTerm,
          locale: null,
        },
      });

      this.motives = data.list || [];
      this.totalMotivesCount = data.count;
      this.calculateNextMotiveLocations();

      this.initialized = true;
    },
    onPageChange(page) {
      this.pagination.currentPage = page;
      this.fetchMotives();
    },
    async save() {
      const printAreasWithMotives = this.printAreas.filter(
        (printArea) => !!printArea.motive
      );
      const saveData = {
        mainDesignId: this.getMainMotive().designId,
        positioning: {},
      };
      printAreasWithMotives.forEach((printArea) => {
        saveData.positioning[printArea.motive.designId] = {
          ideaId: printArea.motive.ideaId,
          location: printArea.id,
        };
      });
      try {
        await apiClient.request({
          method: 'POST',
          url: `/api/v1/users/${this.$store.state.user.data.id}/ideas`,
          data: saveData,
        });
        this.$router.push({ name: 'partnerarea.ideas' });
      } catch (error) {
        this.displayGeneralError = true;
        return Promise.reject();
      }
    },
    back() {
      this.$router.push({
        name: 'partnerarea.ideas',
        query: { restoreState: true },
      });
    },
    search() {
      this.pagination.currentPage = 0;
      this.fetchMotives();
    },
    clearSearchTerm() {
      this.searchTerm = null;
      this.search();
    },
    toggleMotiveSelection(motive) {
      const printArea = this.getPrintAreaForMotive(motive);
      if (printArea) {
        printArea.motive = null;
        this.calculateNextMotiveLocations();
        this.autoAssignMainMotive();
      } else {
        this.addMotive(motive);
      }
      this.displayGeneralError = false;
    },
    getPrintAreaForMotive(motive) {
      return this.printAreas.find(
        (printArea) =>
          printArea.motive && printArea.motive.designId === motive.designId
      );
    },
    autoAssignMainMotive() {
      if (this.getMainMotive()) {
        return;
      }

      const printAreasWithMotives = this.printAreas.filter(
        (printArea) => !!printArea.motive
      );
      if (printAreasWithMotives.length) {
        printAreasWithMotives[0].motive.main = true;
      }
    },
    addMotive(motive) {
      const freeLocation = this.motiveData[motive.designId].nextFreeLocation;

      if (freeLocation) {
        const printArea = this.getPrintAreaByLocation(freeLocation.location);
        printArea.motive = {
          designId: motive.designId,
          ideaId: motive.ideaId,
          name: motive.name,
          locations: motive.locations,
          href: motive.resources[0].href,
          backgroundColor: motive.backgroundColor,
          main: this.getMainMotive() ? false : true,
        };

        this.calculateNextMotiveLocations();
      }
    },
    getMainMotive() {
      const printAreaWithMainMotive = this.printAreas.find(
        (printArea) => printArea.motive && printArea.motive.main
      );
      return printAreaWithMainMotive ? printAreaWithMainMotive.motive : null;
    },
    getFreeLocationForMotive(motive) {
      return motive.locations
        .sort((b, c) => c.primary - b.primary)
        .filter(({ location }) => !!this.getPrintAreaByLocation(location))
        .find(({ location }) => {
          const printArea = this.getPrintAreaByLocation(location);
          return !printArea.motive;
        });
    },
    getPrintAreaByLocation(location) {
      return this.printAreas.find((printArea) => printArea.id === location);
    },
    calculateNextMotiveLocations() {
      this.motives.forEach((motive) => {
        this.motiveData[motive.designId] = {
          nextFreeLocation: this.getFreeLocationForMotive(motive),
          currentPrintArea: this.getPrintAreaForMotive(motive),
        };
      });
    },
    changePrintAreaOfMotive(motive, printArea) {
      const currentPrintArea = this.getPrintAreaForMotive(motive);
      currentPrintArea.motive = null;

      if (printArea.motive) {
        currentPrintArea.motive = printArea.motive;
      }
      printArea.motive = motive;

      this.calculateNextMotiveLocations();
    },
    onPrintAreaChange({ motive, printArea }) {
      this.changePrintAreaOfMotive(motive, printArea);
      this.displayGeneralError = false;
    },
    getTranslatedMotiveLocations(motive) {
      return motive.locations
        .map((l) => this.$t(`DESIGNS.PRINTAREA.LOCATION.${l.location}`))
        .join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

@keyframes slidein {
  0% {
    margin-left: -320px;
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}

.slidein-enter-active {
  animation: slidein 0.25s linear;
}
.slidein-leave-active {
  animation: slidein 0.25s reverse;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.multi-design-idea {
  display: flex;
  height: 100%;
}

.selected-motives {
  width: 400px;
  flex-shrink: 0;
  background-color: $grey0;
  border-right: 1px solid $grey20;
  padding: 24px;

  @include small-desktop {
    width: 300px;
  }

  h1 {
    margin: 0;
  }

  & > p {
    margin-top: 16px;
    color: $grey60;
  }

  .subheading,
  .general-error {
    line-height: 1.5;
  }

  .general-error {
    color: $pa-color-red;
    font-weight: bold;
  }
}

.multi-design-content {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div {
    flex-grow: 1;
    padding: 24px;
    width: 100%;

    & > div {
      max-width: 1400px;
      margin: 0 auto;
    }
  }
}
.header {
  display: flex;
  margin-bottom: 16px;

  h2 {
    margin: 0 0 8px 0;
  }

  p {
    margin: 0;
    color: $grey60;
  }

  .search-wrapper {
    margin-left: auto;
  }
}

.motives {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  .motive {
    padding: 8px;
    flex: 1 1 180px;
    min-width: 180px;

    &.disabled {
      .disabled-overlay {
        display: block;
      }
    }

    &.selected {
      .card {
        box-shadow: 0 0 0 2px $pa-color-main;
      }
    }
  }

  .card {
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
  }

  .disabled-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: transparentize($grey0, 0.3);
    opacity: 1;
    border-radius: 4px;

    :deep(span) {
      width: 100%;
      height: 100%;
    }
  }

  .image-wrapper {
    position: relative;

    &:before {
      content: ' ';
      display: block;
      padding-top: 100%;
    }

    .image {
      position: absolute;
      left: 0;
      top: 0;
      padding: 5px;

      :deep(img) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .checkbox {
    position: absolute;
    top: 8px;
    left: 8px;
  }

  .footer {
    background-color: $grey0;
    padding: 8px;
    @extend .text-truncate;
  }
}

.no-search-result {
  text-align: center;
  margin-top: 80px;
  @extend .text-xl;
  font-weight: bold;
}
</style>

<style lang="scss">
.no-printarea-available {
  text-align: left;

  .topic {
    margin-bottom: 8px;
  }

  p {
    margin: 0;
  }
}

#application.state-partnerarea-createMultiDesignIdea {
  overflow-y: initial;

  .navbar-main {
    display: none;
  }

  #main-content {
    overflow: initial;
    margin-left: 0;
  }
}
</style>

import accountValidator from '@/api/validators/account/accountValidator';
import accountService from '@/api/accountService/accountService';
import pdvService from '@/partnerDataValidation/service/pdvService';
import { isEqual, deepCopy } from '@/utils';
import accountAlerts from '@/api/models/accountAlerts';
import MFA_STATES from '@/states/mfaStates';

function resetValidationErrorForField(validation, field) {
  if (validation.errors[field]) {
    delete validation.errors[field];
  }
  return validation;
}

function validateFields(state, field) {
  if (!state.validate) {
    return;
  }

  if (Array.isArray(field)) {
    field.forEach((f) => validateFields(state, f));
  } else {
    resetValidationErrorForField(state.validation, field);
    const validationResult = accountValidator.validateByFieldName(
      field,
      state.current
    );
    state.validation = accountValidator.mergeResults(
      state.validation,
      validationResult
    );
  }
}

export default {
  namespaced: true,
  state: {
    current: null,
    originalData: null,
    validate: false,
    validation: {
      errors: {},
      result: true,
    },
    pdvState: null,
    originalPdvState: null,
    softCheckFailed: false,
    dataBoxes: {
      personal: {
        valid: true,
        expanded: true,
        fields: [
          'firstName',
          'lastName',
          'company',
          'countryId',
          'city',
          'postalCode',
          'street',
          'street2',
          'stateId',
        ],
        hasChanged(currentData, originalData) {
          return !isEqual(
            {
              firstName: originalData.user.firstName,
              lastName: originalData.user.lastName,
              company: originalData.user.company,
              isCompany: originalData.isCompany,
              countryId: originalData.user.countryId,
              city: originalData.user.city,
              postalCode: originalData.user.postalCode,
              street: originalData.user.street,
              street2: originalData.user.street2,
              stateId: originalData.user.stateId,
            },
            {
              firstName: currentData.user.firstName,
              lastName: currentData.user.lastName,
              company: currentData.user.company,
              isCompany: currentData.isCompany,
              countryId: currentData.user.countryId,
              city: currentData.user.city,
              postalCode: currentData.user.postalCode,
              street: currentData.user.street,
              street2: currentData.user.street2,
              stateId: currentData.user.stateId,
            }
          );
        },
      },
    },
    payoutLimitReached: false,
  },
  getters: {
    alerts: (state, getters, rootState, rootGetters) => {
      if (!state.current) {
        return [];
      }

      const alerts = [];

      // PDV ALERTS
      if (state.pdvState && Boolean(pdvService.getUIState(state.pdvState))) {
        if (
          pdvService.getUIState(state.pdvState) ===
          pdvService.UI_STATES.LETTER_SENT
        ) {
          alerts.push(accountAlerts.PDV_LETTER_SENT);
        } else if (
          pdvService.getUIState(state.pdvState) ===
          pdvService.UI_STATES.PDV_FAILED
        ) {
          alerts.push(accountAlerts.PDV_FAILED);
        } else if (
          pdvService.getUIState(state.pdvState) ===
          pdvService.UI_STATES.PDV_FULLY_FAILED
        ) {
          alerts.push(accountAlerts.PDV_FULLY_FAILED);
        }
      }

      // TAXATION EU ALERTS
      if (SPRD.PLATFORM === 'EU' && state.payoutLimitReached) {
        if (rootGetters['userPaymentInfo/paymentDataMissing']) {
          alerts.push(accountAlerts.PAYMENT_DATA_MISSING);
        }

        if (
          getters.hasSavedAddress &&
          !rootGetters['userTaxation/taxationValid']
        ) {
          alerts.push(accountAlerts.TAXATION_INVALID);
        }

        if (
          getters.hasSavedAddress &&
          !rootGetters['userTaxation/taxationConsistent']
        ) {
          alerts.push(accountAlerts.TAXATION_INCONSISTENT);
        }

        if (getters.hasSavedAddress && !getters.taxationFormValid) {
          alerts.push(accountAlerts.TAX_FORM_INVALID);
        }
      }

      if (
        SPRD.PLATFORM === 'EU' &&
        getters.hasSavedAddress &&
        getters.isTaxationFormAboutToExpire
      ) {
        alerts.push(accountAlerts.TAX_FORM_EXPIRES);
      }

      // TAXATION NA ALERTS
      if (
        SPRD.PLATFORM === 'NA' &&
        getters.hasSavedAddress &&
        rootGetters['userTrolley/addressMismatch']
      ) {
        alerts.push(accountAlerts.TROLLEY_ADDRESS_MISMATCH);
      }

      if (
        SPRD.PLATFORM === 'NA' &&
        rootGetters['statistics/lifetimeSales'] > 0
      ) {
        if (rootGetters['userTrolley/paymentDataMissing']) {
          alerts.push(accountAlerts.TROLLEY_PAYMENT_DATA_MISSING);
        }

        if (
          getters.hasSavedAddress &&
          rootGetters['userTrolley/taxationMissing']
        ) {
          alerts.push(accountAlerts.TROLLEY_TAX_FORM_MISSING);
        }

        if (
          getters.hasSavedAddress &&
          rootGetters['userTrolley/taxationInvalid']
        ) {
          alerts.push(accountAlerts.TROLLEY_TAX_FORM_INVALID);
        }
      }

      // MFA ALERT
      if (rootState.user.mfaStatus.key === MFA_STATES.SETUP.key) {
        alerts.push(accountAlerts.MFA_MISSING);
      }

      return alerts;
    },
    hasSavedAddress: (state) => {
      return (
        state.originalData.user.city &&
        state.originalData.user.street &&
        state.originalData.user.countryId
      );
    },
    isAdmin: (state) => {
      return state.current?.session?.isAdmin;
    },
    isTaxationFormAboutToExpire: (state) => {
      return (
        SPRD.PLATFORM === 'NA' && state.current.user.isTaxationFormAboutToExpire
      );
    },
    taxationFormValid: (state) => {
      if (SPRD.PLATFORM === 'EU') {
        return true;
      }

      return state.originalData.user.taxationValid;
    },
    hasDataChanged: (state) => {
      return !isEqual(state.current, state.originalData);
    },
  },
  actions: {
    save({ state, commit, dispatch }, { forceValidationForFields } = {}) {
      return new Promise((resolve, reject) => {
        commit('validateBoxes', { forceValidationForFields });
        if (state.validation.result) {
          const dataToSave = state.current;
          accountService.saveData(state.originalData, dataToSave).then(
            (result) => {
              commit('setAccountData', { data: result });
              dispatch('userTaxation/fetchTaxationData', null, { root: true });
              dispatch('userTrolley/ensureTrolleyStatusIsUpdated', null, {
                root: true,
              });
              resolve(result);
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          reject();
        }
      });
    },
  },
  mutations: {
    setAccountData(state, { data }) {
      state.originalData = data.origin;
      state.current = deepCopy(data.origin);
      state.pdvState = data.addressValidationState;
      state.originalPdvState = deepCopy(data.addressValidationState);
      state.softCheckFailed = pdvService.softCheckFailed(state.pdvState);

      if (state.softCheckFailed) {
        state.dataBoxes.personal.valid = false;
        state.dataBoxes.personal.expanded = true;
      }
    },
    setPdvState(state, addressValidationState) {
      state.pdvState = addressValidationState;
      state.originalPdvState = deepCopy(addressValidationState);
    },
    setPayoutLimitReached(state, value) {
      state.payoutLimitReached = value;
    },
    revertChanges(state) {
      state.current = deepCopy(state.originalData);
      state.validation = { errors: {}, result: true };
      state.pdvState = deepCopy(state.originalPdvState);
      state.softCheckFailed = pdvService.softCheckFailed(state.pdvState);
    },
    validateBoxes(state, { forceValidationForFields = [] }) {
      state.validate = true;
      //cant access getter in mutations, so we have to re-calculate data again
      const data = state.current;

      const fieldsToValidate = [
        ...new Set([
          ...Object.values(state.dataBoxes)
            .filter((box) => !!box.fields)
            .filter((box) => box.hasChanged(data, state.originalData))
            .reduce((prev, current) => prev.concat(current.fields), []),
          ...forceValidationForFields,
        ]),
      ];

      state.validation = accountValidator.validateMultipleFieldNames(
        fieldsToValidate,
        state.current
      );

      Object.values(state.dataBoxes)
        .filter((box) => !!box.fields)
        .forEach((box) => {
          box.valid = !box.fields.some(
            (field) => state.validation.errors[field]
          );
          if (!box.valid) {
            box.expanded = true;
          }
        });
    },
    setCompanyName(state, company) {
      state.current.user.company = company;
      validateFields(state, 'company');
    },
    setLastName(state, lastName) {
      state.current.user.lastName = lastName;
      validateFields(state, 'lastName');
    },
    setFirstName(state, firstName) {
      state.current.user.firstName = firstName;
      validateFields(state, 'firstName');
    },
    setPhone(state, phone) {
      state.current.user.phone = phone;
    },
    setCountry(state, countryId) {
      state.current.user.countryId = countryId;
      validateFields(state, 'countryId');
    },
    setState(state, stateId) {
      state.current.user.stateId = stateId;
      validateFields(state, 'stateId');
    },
    setStreet(state, street) {
      state.current.user.street = street;
      validateFields(state, 'street');
    },
    setStreet2(state, street2) {
      state.current.user.street2 = street2;
      validateFields(state, 'street2');
    },
    setPostalCode(state, postalCode) {
      state.current.user.postalCode = postalCode;
      validateFields(state, 'postalCode');
    },
    setCity(state, city) {
      state.current.user.city = city;
      validateFields(state, 'city');
    },
  },
};

<template>
  <div class="no-designs-dialog">
    <div class="modal-body">
      <h1>{{ $t('NO_DESIGNS.DIALOG.HEADING') }}</h1>
      <p class="subheading">
        {{ $t('NO_DESIGNS.DIALOG.SUBHEADING') }}
      </p>
      <div class="choices">
        <a
          v-for="choice in possibleChoices"
          :key="choice.key"
          href="#"
          @click.prevent="choice.onClick"
        >
          <img :src="choice.image" />
          <h3>{{ $t(choice.heading) }}</h3>
          <p>
            {{ $t(choice.subheading) }}
          </p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import localeService from 'src/app/commons/localeService/localeService';
import graphicsServiceLinks from '@/externalLinks/graphicsServiceLinks';

export default {
  name: 'NoDesignsDialog',
  props: ['modalInstance', 'data'],
  data() {
    return {
      CHOICES: {
        GENERATE: {
          key: 'GENERATE',
          heading: 'NO_DESIGNS.DIALOG.QUICK_START.HEADING',
          subheading: 'NO_DESIGNS.DIALOG.QUICK_START.SUBHEADING',
          image: '/images/chillout.svg',
          onClick: this.generate,
        },
        GRAPHICS_SERVICE: {
          key: 'GRAPHICS_SERVICE',
          heading: 'GRAPHICS_SERVICE.NAME',
          subheading: 'GRAPHICS_SERVICE.EXPLANATION',
          image: '/images/customer-service.svg',
          onClick: this.goToGraphicsService,
        },
      },
    };
  },
  computed: {
    possibleChoices() {
      return [this.CHOICES.GENERATE, this.CHOICES.GRAPHICS_SERVICE];
    },
  },
  methods: {
    generate() {
      this.modalInstance.close('GENERATE');
    },
    goToGraphicsService() {
      // no spreadshop graphics service for now, always link to mp variant
      const partnerType = 'MP';
      const links = graphicsServiceLinks[partnerType][SPRD.PLATFORM];

      window.open(links[localeService.getLocale()] || links.default, '_blank');

      this.modalInstance.close('GRAPHICS_SERVICE');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.no-designs-dialog {
  max-width: 1000px;
}

h1,
h3 {
  margin: 0;
  color: $grey80;
}

.subheading {
  margin-top: 16px;
  color: $grey60;
}

img {
  width: 120px;
  margin-bottom: 16px;
  height: 105px;
}

.choices {
  margin-top: 24px;
  display: flex;

  & > a {
    width: 50%;
    min-width: 350px;
    background-color: $grey5;
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid $grey5;

    &:hover {
      border-color: $pa-color-main;
    }

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }

    p {
      margin: 16px 0 0 0;
      color: $grey60;
      text-align: center;
    }
  }

  .mails {
    a {
      color: $grey80;
    }

    h3:nth-child(2) {
      margin-top: 12px;
    }
  }
}
</style>

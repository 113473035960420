import * as Sentry from '@sentry/browser';
import versionService from 'src/app/commons/versionService/versionService';
import accountService from '@/api/accountService/accountService';
import store from '@/store/Store.js';
import statisticsService from 'src/app/commons/api/statisticsService/statisticsService';
import { getExternalLinks } from '@/externalLinks/externalLinks';
import pdvService from '@/partnerDataValidation/service/pdvService';
import OnboardingDialog from 'src/app/components/onboarding/OnboardingDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import eventBus from '@/eventBus/eventBus';
import { initializeTranslations } from '@/translate/i18n';
import router from 'src/app/router/router';
import analyticsService from '@/tracking/analytics';
import RecapService from '@/dialogs/recap/RecapService';

export default {
  async onAppStart() {
    initializeTranslations({
      displayKeys: window.location?.href?.includes(
        '?displayTranslationKeys=true'
      ),
    });

    store.dispatch('platform/fetchCurrencies');
    store.dispatch('application/processUrlParams');
    store.dispatch('platform/fetchMetaColors');

    eventBus.$on('shoparea:shopUpdate', () => {
      store.dispatch('user/fetchShops');
      store.dispatch('user/fetchPointsOfSale');
    });

    this.watchForOnboardingChanges();

    if (!SPRD?.LOCAL_RUNTIME) {
      versionService.startVersionCheck();
    }
  },
  async watchForOnboardingChanges() {
    store.watch(
      (state, getters) => getters['onboarding/finishedRelevantSteps'],
      (newState, oldState) => {
        const newSteps = newState.steps;
        const oldSteps = oldState.steps;

        if (
          newState.scope === oldState.scope &&
          oldSteps !== null &&
          newSteps !== null &&
          oldSteps.length < newSteps.length
        ) {
          if (store.getters['dialogs/dialogCount'] > 0) {
            const openOnboardingDialogAfterOtherDialogsAreRemoved = () => {
              eventBus.$off(
                'dialogs:removed',
                openOnboardingDialogAfterOtherDialogsAreRemoved
              );
              dialogService.openDialog(OnboardingDialog, {
                oldSteps,
                newSteps,
              });
            };
            eventBus.$on(
              'dialogs:removed',
              openOnboardingDialogAfterOtherDialogsAreRemoved
            );
          } else {
            dialogService.openDialog(OnboardingDialog, { oldSteps, newSteps });
          }
        }
      }
    );
  },
  async onUserLogin() {
    if (store.state.user.data.guest) {
      store.dispatch('user/logout', {
        redirectTargetAfterLogout: getExternalLinks().START_SELLING,
      });
      return;
    }

    if (SPRD.ERROR_LOGGING_ACTIVE) {
      Sentry.configureScope(function (scope) {
        scope.setUser({ id: store.state.user.data.id });
      });
    }
    analyticsService.setUserId();

    store.dispatch('platform/fetchShippingStates');
    store.dispatch('platform/fetchShippingCountries');
    store.dispatch('platform/fetchLanguages');
    store.dispatch('platform/fetchCountries');
    store.dispatch('settings/fetchSettings');
    store.dispatch('smartFaq/init');

    await Promise.all([
      store.state.settings.onDataLoaded,
      store.dispatch('user/fetchShops'),
      store.dispatch('userPaymentInfo/fetchPaymentInfo'),
      store.dispatch('userTaxation/fetchTaxationData'),
      store.dispatch('userTrolley/fetchTrolleyStatus'),
      accountService.getData().then((data) => {
        store.commit('account/setAccountData', { data });

        if (store.getters['account/isAdmin']) {
          analyticsService.disable();
        }
      }),
      store.dispatch('ideas/checkIdeaIntents'),
      store.dispatch('statistics/fetchLifetimeStatistics'),
    ]);

    if (store.state.application.isMobileApp) {
      return;
    }

    if (store.getters['settings/publishingCoreEnabled']) {
      await store.dispatch('publishingCore/fetchData');
    }

    store.dispatch('user/updateMfaStatus');
    store.dispatch('onboarding/initialize');

    const registrationRoutes = [
      'partnerarea.register',
      'partnerarea.shopRegister',
      'partnerarea.themes',
    ];

    if (
      !registrationRoutes.includes(
        router.currentRoute?.value?.meta?.previousState?.name
      ) &&
      !registrationRoutes.includes(router.currentRoute?.value?.name)
    ) {
      if (
        !versionService.getLastVisitedVersion() &&
        !versionService.getLastVisitedSpreadAppVersion()
      ) {
        // new partner registered outside PA and SpreadApp
        // TODO: test

        // type 'BOTH' should not be possible for a partner first visiting PA, so its most likely a shop partner
        if (store.getters['user/partnerType'] === 'BOTH') {
          store.dispatch('settings/setSetting', {
            key: 'MP_ENABLED',
            value: false,
          });
        }

        dialogService.openDialog(OnboardingDialog, { welcome: true });
      } else if (!versionService.getLastVisitedVersion()) {
        // partner registered in SpreadApp, first visit in PA
        dialogService.openDialog(OnboardingDialog, { welcome: true });
      }
    }

    versionService.persistLastVisitedVersion();
    store.dispatch('user/fetchProductTypes');
    store.dispatch('platform/fetchProductTypeShares');
    store.dispatch('notifications/startPolling');
    store.dispatch('dms/startPolling');
    store.dispatch('metrics/clearCache');

    try {
      await Promise.all([
        statisticsService.getCreditOverview().then((creditOverview) => {
          store.commit(
            'account/setPayoutLimitReached',
            creditOverview ? creditOverview.payoutLimitReached : false
          );
        }),
        pdvService.fetchState().then((state) => {
          if (
            pdvService.getCurrentState(state) ===
            pdvService.STATES.CONFIRM_NEEDED
          ) {
            router.push({ name: 'partnerarea.addressValidation' });
          }
        }),
      ]);
    } catch (error) {
      console.error(error);
    }

    RecapService.checkForUnseenRecap();
  },
};

import userCoreService from '@/api/userAccount/userCoreService';
import { setIntervalN } from '@/utils';

export default {
  namespaced: true,
  state: {
    addressComparison: null,
    taxDataStatus: null,
    bankDetails: null,
    trolleyUser: null,
  },
  getters: {
    paymentDataMissing: (state) => !state.bankDetails,
    taxationInvalid: (state) =>
      state.taxDataStatus?.isSubmitted &&
      state.taxDataStatus?.status === 'voided',
    taxationMissing: (state) => !state.taxDataStatus?.isSubmitted,
    addressMismatch: (state) => state.trolleyUser && !state.addressComparison,
  },
  mutations: {
    setData(
      state,
      { addressComparison, taxDataStatus, bankDetails, trolleyUser }
    ) {
      state.addressComparison = addressComparison;
      state.taxDataStatus = taxDataStatus;
      state.bankDetails = bankDetails;
      state.trolleyUser = trolleyUser;
    },
  },
  actions: {
    async fetchTrolleyStatus({ commit }) {
      const data = await userCoreService.getTrolleyStatus();
      commit('setData', data);
    },
    ensureTrolleyStatusIsUpdated({ dispatch }) {
      dispatch('fetchTrolleyStatus');
      setIntervalN(() => dispatch('fetchTrolleyStatus'), 2000, 5);
    },
  },
};

<template>
  <div class="youtube-module" v-if="display">
    <ConsentRequired type="social" class="iframe-wrap">
      <iframe
        width="100%"
        height="315"
        :src="youtubeLink"
        title="YouTube video player"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </ConsentRequired>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConsentRequired from 'src/app/components/consent/ConsentRequired.vue';
import localeService from '@/localeService/localeService';
export default {
  name: 'YoutubeMobule',
  components: {
    ConsentRequired,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      hasShops: 'user/hasShops',
    }),
    display() {
      return this.hasShops;
    },
    youtubeLink() {
      if (localeService.getLanguage() === 'de') {
        return 'https://www.youtube.com/embed/videoseries?list=PLUIBeud7JfIJZDZHykEveT2PSbnMj1s7g';
      } else if (localeService.getLanguage() === 'fr') {
        return 'https://www.youtube.com/embed/videoseries?list=PLUIBeud7JfILsvhkEIHzNsW2qAPnzJ3hz';
      } else {
        return 'https://www.youtube.com/embed/videoseries?list=PLUIBeud7JfIKaExOeFczAoZ7KwdyfIpcN';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins';
@import 'src/scss/styleguide/type';

.youtube-module {
  display: flex;
  overflow: hidden;
  padding: 0 !important;
  min-height: 315px;

  .iframe-wrap {
    width: 100%;
  }

  iframe {
    height: 100%;
  }
}
</style>

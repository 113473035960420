<template>
  <div class="trolley-iframe-wrapper" ref="trolleyIframeWrapper">
    <LoadingHeart v-if="!loaded" class="loader" />
    <iframe v-if="iframeUrl" :class="{ hidden: !loaded }" :src="iframeUrl" />
    <div class="error-msg" v-if="loaded && !iframeUrl">
      <Icon icon="rocket-crashed" />
      <h3>
        {{ $t('GENERAL.ERROR.HEADING') }}
      </h3>
      <p>
        {{ $t('GENERAL.ERROR.TEXT1') }}
      </p>
      <p>
        {{ $t('GENERAL.ERROR.TEXT2') }}
      </p>
      <button class="link link-main icon-link" @click="reload()">
        <Icon icon="refresh" />
        {{ $t('GENERAL.ERROR.REFRESH') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import userCoreService from '@/api/userAccount/userCoreService';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import analytics from '@/tracking/analytics';

const store = useStore();
let iframeUrl = ref(null);
let loaded = ref(null);
// todo: switch to useTemplateRef with vue 3.5+
const trolleyIframeWrapper = ref(null);

async function init() {
  loaded.value = false;
  try {
    iframeUrl.value = await userCoreService.getTrolleyIFrame();
    analytics.logEvent('trolley_iframe_success');
  } catch (error) {
    loaded.value = true;
    analytics.logEvent('trolley_iframe_error');
  }
}

const onTrolleyEvents = (e) => {
  if (
    trolleyIframeWrapper.value?.style &&
    e?.data?.event === 'document.height'
  ) {
    trolleyIframeWrapper.value.style.setProperty(
      'min-height',
      `${e.data.document.height}px`
    );
  } else if (e?.data?.event === 'document.loaded') {
    setTimeout(() => {
      loaded.value = true;
    }, 300);
  } else if (e?.data?.event === 'module.successful') {
    store.dispatch('userTrolley/ensureTrolleyStatusIsUpdated');
    analytics.logEvent('trolley_module_success');
  }
};

const reload = () => {
  init();
};

onMounted(async () => {
  await init();

  window.addEventListener('message', onTrolleyEvents);
});

onBeforeUnmount(() => {
  window.removeEventListener('message', onTrolleyEvents);
  store.dispatch('userTrolley/fetchTrolleyStatus');
});
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.trolley-iframe-wrapper {
  display: flex;
}

iframe {
  flex: 1;
  width: 100%;
  border: none;

  &.hidden {
    visibility: hidden;
  }
}

.loader {
  padding: 48px;
}

.error-msg {
  & > .icon {
    width: 5em;
    height: 5em;
    margin-bottom: 19px;
    color: $pa-color-red;
  }

  h3 {
    margin: 0 0 12px 0;
  }

  p {
    margin-bottom: 0;
  }

  button {
    margin-top: 12px;
    padding: 12px 8px;
  }
}
</style>

import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';

export function genId(prefix) {
  return `${prefix}-${Math.random().toString(32).substr(2, 8)}`;
}

export function isOrContainsNode(parent, child) {
  return parent === child || (parent.contains && parent.contains(child));
}

export function chunk(array, size) {
  const chunkedResult = [];
  let index = 0;
  while (index < array.length) {
    chunkedResult.push(array.slice(index, size + index));
    index += size;
  }
  return chunkedResult;
}

export function isSubset(superObj, subObj) {
  if (!superObj || !subObj) {
    return false;
  }
  return Object.keys(subObj).every((ele) => {
    if (typeof subObj[ele] == 'object') {
      return isSubset(superObj[ele], subObj[ele]);
    }
    return subObj[ele] === superObj[ele];
  });
}

export { isEqual };

export function deepCopy(obj) {
  return cloneDeep(obj);
}

export function removeNullValues(obj) {
  return Object.entries(obj).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  );
}

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (!bytes) return null;
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
}

export function delay(delayInMs) {
  return new Promise((resolve) => setTimeout(resolve, delayInMs));
}

export function isEmpty(text) {
  return !text || !text.length;
}

export async function retryInIntervalAsync(
  fn,
  { maxTime, interval } = { maxTime: 20000, interval: 5000 },
  iteration = 0
) {
  try {
    const result = await fn();
    return result;
  } catch (error) {
    if (interval * iteration >= maxTime) {
      return Promise.reject();
    } else {
      await delay(interval);
      return retryInIntervalAsync(fn, { maxTime, interval }, iteration + 1);
    }
  }
}

export async function retryInIntervalUntilTrue(
  fn,
  { maxTime, interval } = { maxTime: 20000, interval: 5000 },
  iteration = 0
) {
  const result = fn();
  if (result) {
    return result;
  } else {
    if (interval * iteration >= maxTime) {
      return Promise.reject();
    } else {
      await delay(interval);
      return retryInIntervalUntilTrue(fn, { maxTime, interval }, iteration + 1);
    }
  }
}

export async function sha256(message) {
  try {
    const msgUint8 = new TextEncoder().encode(message);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  } catch (error) {
    return null;
  }
}

export function setIntervalN(callback, delay, repeats) {
  var repeatCount = 0;
  var intervalID = window.setInterval(() => {
    callback();

    if (++repeatCount === repeats) {
      window.clearInterval(intervalID);
    }
  }, delay);
}

export function setLocalStorageItemWithExpiry(key, value, expiryTimeInMs) {
  const now = new Date();

  const item = {
    value,
    expiry: now.getTime() + expiryTimeInMs,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageItemWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

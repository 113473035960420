<template>
  <div class="modal-container idea-upload-dialog dialog-size-md">
    <div class="modal-body">
      <div v-if="mode === 'selection'" class="selection">
        <h1>{{ $t('DESIGNS.UPLOAD.HEADING_SINGLE') }}</h1>
        <p
          class="subheading"
          v-html="
            $t('DESIGNS.UPLOAD.MAXIMUM_AMOUNT', {
              count: fileLimit,
            })
          "
        ></p>
        <label
          for="hiddenFileInput"
          id="upload-btn"
          class="design-upload-area"
          :class="{ dragover: dragging }"
          @dragover.prevent="dragging = true"
          @dragleave.prevent="dragging = false"
          @dragend.prevent="dragging = false"
          @drop.prevent="onFileDrop"
        >
          <Icon icon="upload" />
          <h2>{{ $t('DESIGNS.UPLOAD.HEADER.FIND_DESIGN') }}</h2>
          <p class="text-sm constraints">
            <span>{{ $t('DESIGNS.UPLOAD.FILE_TYPES') }}</span>
            <strong>
              {{ acceptedFileExtensions }}
            </strong>
          </p>
          <input
            type="file"
            multiple
            id="hiddenFileInput"
            ref="hiddenFileInput"
            @change="uploadFiles"
            class="hidden-file-input"
          />
        </label>
        <div class="copyright-hint">
          <small v-html="$t('DESIGNS.UPLOAD.LIST.COPYRIGHT_LABEL')"></small>
        </div>
        <template v-if="aiEnabled">
          <AiContainer
            class="ai-container-margin"
            scaleOnHover
            :borderRadius="4"
          >
            <Banner
              icon="ai-feature"
              inverted
              heading="AI_DESIGN_GENERATOR.UPLOAD_BANNER.HEADING"
              subheading="AI_DESIGN_GENERATOR.UPLOAD_BANNER.SUBHEADING"
              link="AI_DESIGN_GENERATOR.UPLOAD_BANNER.CTA"
              clickable
              @onLinkClick="openAiGenerator"
            />
          </AiContainer>
          <div class="divider"></div>
        </template>

        <Banner
          v-if="ideaCount >= 2"
          class="multiple-designs"
          icon="multipleprintarea"
          heading="DESIGNS.UPLOAD.HEADING_MULTIPLE"
          subheading="DESIGNS.UPLOAD.SUBHEADING_MULTIPLE"
          link="GENERAL.SELECT"
          inverted
          @onLinkClick="createMultiDesignIdea"
        />

        <div class="divider"></div>
        <GraphicsServiceBanner
          class="graphics-service-banner"
          condensed
          linkIcon="outside"
        />
        <Banner
          v-if="embroideryUploadEnabled"
          class="embroidery-banner"
          heading="DESIGNS.UPLOAD.EMBROIDERY.HEADING"
          subheading="DESIGNS.UPLOAD.EMBROIDERY.TEXT"
          link="DESIGNS.UPLOAD.EMBROIDERY.CTA"
          icon="lightbulb"
          @onLinkClick="goToEmbroideryUpload"
          condensed
          linkIcon="outside"
        />
      </div>
      <div v-else class="file-list">
        <h2>
          {{ $t('DESIGNS.UPLOAD.HEADER.SELECT_FILES') }}
        </h2>
        <!-- <p v-if="fileLimitExceeded" v-html="$t('DESIGNS.UPLOAD.MAXIMUM_NUMBER_EXCEEDED', {count: fileLimit})"></p> -->

        <div
          class="design-upload-files"
          :class="{ 'uploads-done': !uploadPending }"
        >
          <div
            class="design-upload-progress"
            v-for="(selectedFile, index) in selectedFiles"
            :key="index"
            :class="{
              success: !selectedFile.uploadPending && selectedFile.success,
            }"
          >
            <div class="design-upload-data">
              <div class="design-name">
                <span>{{ selectedFile.file.name }}</span>
                <Icon
                  class="success-icon"
                  icon="accept"
                  v-show="
                    !selectedFile.uploadPending && !selectedFile.errorType
                  "
                />
                <Icon
                  class="error-icon"
                  icon="close"
                  v-show="!selectedFile.uploadPending && selectedFile.errorType"
                />
              </div>
              <div
                class="design-upload-progress-bar"
                :class="{
                  'upload-error':
                    !selectedFile.uploadPending && !selectedFile.success,
                }"
                :style="{
                  'background-position': 100 - selectedFile.progress + '%',
                }"
              ></div>

              <div
                class="design-upload-message text-sm"
                v-show="selectedFile.errorType"
              >
                <div v-if="selectedFile.errorType === 'FILE_SIZE'">
                  {{ $t('DESIGNS.UPLOAD.LIST.VALIDATION.TOO_BIG') }}
                </div>
                <div v-if="selectedFile.errorType === 'FILE_TYPE'">
                  <span
                    v-html="$t('DESIGNS.UPLOAD.LIST.VALIDATION.FILE_TYPE_HINT')"
                  ></span>
                  <span>
                    {{ acceptedFileExtensions }}
                  </span>
                </div>
                <div v-if="selectedFile.errorType === 'LIMIT_REACHED'">
                  {{ $t('DESIGNS.UPLOAD.LIST.VALIDATION.LIMIT_REACHED') }}
                </div>
                <div
                  v-if="
                    !['FILE_SIZE', 'FILE_TYPE', 'LIMIT_REACHED'].includes(
                      selectedFile.errorType
                    )
                  "
                >
                  {{ $t('DESIGNS.UPLOAD.LIST.VALIDATION.GENERAL_ERROR') }}
                </div>
              </div>
            </div>
          </div>
          <div class="upload-success" v-if="showSuccess">
            <Icon class="icon" icon="accept" />
            <strong class="text-xl">{{ $t('DESIGNS.UPLOAD.SUCCESS') }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import designUploadService from '@/api/designUploadService/designUploadService';
import { mapGetters, mapState } from 'vuex';
import Banner from 'src/app/components/banners/Banner.vue';
import GraphicsServiceBanner from 'src/app/components/banners/GraphicsServiceBanner.vue';
import analytics from '@/tracking/analytics';
import dialogService from '@/dialogs/wrapper/dialogService';
import AiDesignGeneratorDialog from '@/designGenerator/AiDesignGeneratorDialog.vue';
import AiContainer from 'src/app/components/ai/AiContainer.vue';

export default {
  name: 'IdeaUploadDialog',
  props: ['modalInstance', 'data'],
  components: {
    AiContainer,
    Banner,
    GraphicsServiceBanner,
  },
  data() {
    return {
      fileLimit: designUploadService.getFileLimit(),
      dragging: false,
      mode: 'selection',
      uploadingFiles: null,
      uploadPending: true,
      atLeastOneSuccessFullUpload: false,
      showSuccess: false,
      selectedFiles: [],
      fileLimitExceeded: false,
    };
  },
  computed: {
    ...mapState({
      ideaCount: (s) => s.ideas.list.length,
    }),
    ...mapGetters({
      shops: 'user/shops',
      getSetting: 'settings/getSetting',
      aiEnabled: 'settings/aiEnabled',
    }),
    acceptedFileExtensions() {
      const acceptedFileTypes = designUploadService.getAcceptedFileTypes();

      return acceptedFileTypes
        .map((fileType) => fileType.extensions[0])
        .join(', ');
    },
    embroideryUploadEnabled() {
      return this.getSetting('EMBROIDERY');
    },
  },
  created() {
    if (this.data?.files) {
      this.onFileSelect(this.data.files);
    }
  },
  methods: {
    closeModal() {
      if (this.uploadingFiles && this.uploadingFiles.uploads) {
        this.uploadingFiles.uploads.forEach((f) => {
          f.upload && f.upload.abort();
        });
      }

      if (this.atLeastOneSuccessFullUpload) {
        this.close();
      } else {
        this.modalInstance.dismiss();
      }
    },
    close() {
      this.modalInstance.close(this.selectedFiles);
    },
    createMultiDesignIdea() {
      this.$router.push({ name: 'partnerarea.createMultiDesignIdea' });
    },
    uploadFiles(evt) {
      if (!(evt instanceof Event) || evt.isTrusted === false) {
        return;
      }

      this.onFileSelect([...evt.target.files]);
      this.$refs.hiddenFileInput.value = '';
    },
    onFileDrop(evt) {
      if (!(evt instanceof Event) || evt.isTrusted === false) {
        return;
      }

      const files = evt.dataTransfer
        ? evt.dataTransfer.files
        : evt.srcElement.files;
      this.onFileSelect([...files]);
      this.dragging = false;
    },
    switchMode(newMode) {
      this.mode = newMode;
    },
    onFileSelect(files) {
      if (files.length === 0) {
        return;
      }

      if (files.length > designUploadService.getFileLimit()) {
        files = files.splice(0, designUploadService.getFileLimit());
        this.fileLimitExceeded = true;
      }

      this.selectedFiles = [];

      this.switchMode('upload');

      for (let i = 0, len = files.length; i < len; i++) {
        files[i].index = i;

        this.selectedFiles[i] = {
          file: files[i],
          uploadPending: true,
          success: false,
          errorType: null,
          progress: 0,
        };
      }

      analytics.logEvent('design_upload', {
        value: files.length,
        files: files.length,
      });

      this.uploadingFiles = designUploadService.uploadFiles(
        files,
        {
          progress: (file, evt) => {
            this.selectedFiles[file.index].progress = parseInt(
              (100.0 * evt.loaded) / evt.total
            );
          },
          success: (file) => {
            this.atLeastOneSuccessFullUpload = true;

            this.selectedFiles[file.index].success = true;
            this.selectedFiles[file.index].uploadPending = false;
            this.selectedFiles[file.index].progress = 100;
          },
          error: (file, error) => {
            this.selectedFiles[file.index].failed = true;
            this.selectedFiles[file.index].progress = 100;
            this.selectedFiles[file.index].uploadPending = false;

            if (error && error.status) {
              this.selectedFiles[file.index].errorType = this.getErrorType(
                error.status
              );
            } else {
              this.selectedFiles[file.index].errorType = error;
            }
          },
        },
        this.data?.uploadFlags
      );

      this.uploadingFiles.promise
        .catch(() => {})
        .finally(() => {
          const successfulUploads = this.selectedFiles.filter(
            (file) => file.success
          );
          const failedUploads = this.selectedFiles.filter(
            (file) => file.failed
          );

          this.uploadPending = false;

          analytics.logEvent('design_upload_finished', {
            success: successfulUploads.length,
            failed: failedUploads.length,
            hasShop: !!this.shops,
          });

          if (successfulUploads.length === this.selectedFiles.length) {
            this.showSuccess = true;
            setTimeout(() => {
              this.close();
            }, 2500);
          }
        });
    },
    getErrorType(errorCode) {
      switch (errorCode) {
        case 415:
          return 'FILE_TYPE';
        case 413:
          return 'FILE_SIZE';
        case 429:
          return 'LIMIT_REACHED';
        default:
          return 'UNDEFINED';
      }
    },
    goToEmbroideryUpload() {
      window.open('https://spreadshirt.net', '_blank'); // TODO: add correct link
    },
    async openAiGenerator() {
      this.modalInstance.dismiss();
      try {
        await dialogService.openDialog(AiDesignGeneratorDialog, null, {
          staticBackdrop: true,
          preventEsc: true,
        });
      } catch (error) {
        analytics.logEvent('ai_generator_skip');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';

.idea-upload-dialog {
  min-width: 600px;
}

.selection {
  h1,
  h3 {
    margin: 0;
  }

  .subheading {
    margin: 8px 0 16px 0;
    color: $grey60;
  }

  .design-upload-area {
    display: flex;
    background-color: $grey5;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 82px;
    border-radius: 4px;
    cursor: pointer;

    &:hover,
    &.dragover {
      background-color: $grey15;
    }

    h2 {
      margin: 12px 0 0 0;
      color: $pa-color-main;
    }

    .icon {
      width: 32px;
      height: 32px;
      color: $pa-color-main;
    }

    .constraints {
      position: absolute;
      margin: 0;
      bottom: 16px;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  .copyright-hint {
    margin-top: 8px;
    color: $grey60;
  }

  :deep(.copyright-hint) {
    a {
      @extend .link-main;
    }
  }

  .multiple-designs {
    margin-top: 24px;
  }

  .ai-container-margin {
    margin-top: 24px;
  }

  .divider {
    margin: 24px -40px;
    height: 2px;
    background-color: $grey5;
  }

  .graphics-service-banner {
    margin: 0;
  }

  .embroidery-banner {
    margin-top: 18px;
  }
}

.file-list {
  @keyframes upload-list-fade {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
      min-height: 80px;
      height: 80px;
    }

    100% {
      opacity: 0;
      min-height: 0;
      height: 0;
    }
  }

  @keyframes upload-success-fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .design-upload-files {
    width: 100%;
    padding-bottom: 10px;
    min-height: 80px;
    margin-top: 24px;
    position: relative;
  }

  .design-upload-message {
    padding: 5px 10px 10px 0;
    color: $pa-color-red;
  }

  .design-upload-progress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, $pa-color-main 50%, $grey5 50%);
    background-size: 200% 100%;
    background-position: 100%;
    height: 4px;
    max-height: 4px;
    border-radius: 4px;
    transition: all 0.5s ease;

    &.upload-error {
      background: $pa-color-red;
      background-position: 100%;
    }
  }

  .upload-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation-name: upload-success-fade;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;

    .icon {
      width: 32px;
      height: 32px;
      margin-bottom: 12px;
      color: $pa-color-main;
    }
  }

  .design-upload-progress {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 80px;
    opacity: 1;

    &.success {
      animation-duration: 2s;
      animation-name: upload-list-fade;
      animation-fill-mode: forwards;
    }

    .design-upload-data {
      width: 100%;

      .design-name {
        display: flex;
        padding-bottom: 3px;

        & > span {
          flex-grow: 1;
          @extend .text-xl;
        }

        .icon {
          width: 16px;
          height: 16px;
        }

        .success-icon {
          color: $pa-color-main;
        }

        .error-icon {
          color: $pa-color-red;
        }
      }
    }
  }
}
</style>
